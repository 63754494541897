@import 'styles/variables';
.noLoanSection{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 80px 0;
}
.card{
    width: 700px;
    display: flex;
    padding: 50px 80px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.10);
    position: relative;
    z-index: 10;
    transform-style: preserve-3d;

    &:before{
        content: '';
        position: absolute;
        bottom: 20px;
        left: -32px;
        width: 108px;
        height: 108px;
        background: url(./../../img/whiteBallBig.png) no-repeat center;
        transform: translateZ(-1px);
    }
    &:after{
        content: '';
        position: absolute;
        bottom: 32%;
        right: -83px;
        width: 149px;
        height: 149px;
        background: url(./../../img/greenBallShadow.png) no-repeat center;
        transform: translateZ(-1px) scale(.8);
    }

    @media screen and (max-width: $tablet) {
        width: 100%;
        padding: 25px 40px;
        margin: 0 10px;
    }
    @include for-mobile-only {
        &:after, &:before {
            width: 0;
            height: 0;
            background: transparent;
        }
    }
}
.hourglassImg{
    width: 192px;
    height: 128px;
}
.title{
    text-align: center;
}
.description{
    text-align: center;
}
.link{
    color: $green;
}

@import 'styles/variables';
@import 'styles/global';

.advantagesSection {
    padding: 48px 0 0;

    @include for-tablet-land {
        padding: 96px 0 0;
    }

    .title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        margin-bottom: 32px;

        @include for-tablet-land {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 348x;
        }
    }

    .cardsContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include for-tablet-land {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
        }

        @include for-notebook {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .card {
        display: flex;
        border: 1px solid #E2E4E9;
        border-radius: 12px;

        @include for-tablet-land {
            flex-direction: column-reverse;
            justify-content: space-between;
        }

        .cardImage {
            flex-shrink: 0;
            width: 90px;
            min-height: 126px;
            margin: 8px;
            border-radius: 8px;
            background-position: center;
            background-size: cover;

            @include for-tablet-land {
                width: auto;
                min-height: 236px;
                margin: 12px;
            }
        }

        .cardContent {
            padding: 16px 8px 16px 16px;

            h5 {
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
                margin-bottom: 8px;
            }

            p {
                color: #608185;
                font-size: 14px;
                line-height: 18xp;
            }

            @include for-tablet-land {
                padding: 24px;

                h5 {
                    font-size: 32px;
                    line-height: 40px;
                    margin-bottom: 24px;
                }

                p {
                    font-size: 16px;
                    line-height: 24xp;
                }
            }
        }
    }
}

.green {
    color: $green;
}

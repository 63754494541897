@import "styles/variables";

.main {
    @include for-mobile-only{
        padding-top: 57px;
    }
    &__title {
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 32px;
        margin-top: 40px;
        @include for-mobile-only{
            position: fixed;
            background: white;
            width: 100%;
            top: 0;
            z-index: 8;
            left: 0;
            font-size: 24px;
            padding: 32px 42px 24px 20px;
            line-height: 24px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &__tabs {
        counter-reset: line-number;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
        padding: 0 7.14%;
        margin: 0 -7.14% 40px;
        position: relative;
        z-index: 9;
        @include for-mobile-only {
            padding-top: 40px;
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        &_disabled {
            .main__item {
                cursor: wait;
            }
        }
    }

    &__item {
        cursor: pointer;
        position: relative;
        white-space: normal;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        counter-increment: line-number;
        margin: 0 10px;
        &:first-child {
            margin: 0 10px 0 0;
        }
        &:last-child {
            margin: 0 0 0 10px;
        }

        &::after {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            content: counter(line-number);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            line-height: 32px;
            border-radius: 50%;
            border: 2px solid $colorInverse;
            background-color: $borderDark;
            color: $colorInverse;
            transition: background-color 0.3s;
        }

        span {
            display: block;
            margin-top: 44px;
            font-size: 14px;
            line-height: 20px;
            user-select: none;
            width: 128px;

            @include for-tablet-land {
                width: 186px;
            }
        }

        & + & {

            &::before {
                content: "";
                position: absolute;
                top: 15px;
                right: 50%;
                width: 156px;
                height: 4px;
                background-color: $borderDark;
                z-index: -1;
                transition: background-color 0.3s;

                @include for-tablet-land {
                    width: 190px;
                }
            }
        }

        &--active {
            &::after {
                background-color: $green;
            }

            & + & {
                &::before {
                    background-color: $green;
                }
            }
        }
        &--current {
            font-weight: bold;
            span {
                color: $green;
            }
        }
    }

    &__buttons {
        margin-top: 32px;

        &--once {
            max-width: 300px;
        }

        &--double {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 16px;
        }
    }

    @include for-tablet {

        &__tabs {
            padding: 0 40px;
            margin: 0 -40px 40px;
        }

        &__buttons {
            &--once {
                max-width: 236px;
            }

            &--double {
                grid-template-columns: 172px 135px;
            }
        }
    }

    @media (min-width: 1171px) { //именно на 1171 модалка с оформлением займа начинает уменьшаться и нужен скролл
        &__tabs {
            overflow: hidden;
            padding: 0;
            margin: 0 0 40px;
        }
    }
}

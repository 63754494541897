@import 'styles/variables';

.checkbox {
    font-size: 16px; // переделать только для компонентов с типом small
    user-select: none;

    &,
    .text_wrapper,
    .text_wrapper i {
        display: flex;
        align-items: center;
    }

    .text_wrapper {
        cursor: pointer;

        &--small {
            font-size: 14px;
            line-height: 20px;
        }

        &--default {
            font-size: 16px;
            line-height: 24px;
        }

        &--big {
            font-size: 16px;
            line-height: 24px;

            @include for-tablet {
                font-size: 18px;
                line-height: 26px;
            }
        }

        i {
            min-width: 24px;
            height: 24px;
            border: 2px solid $borderPrimary;
            border-radius: 4px;
            margin-right: 16px;
            justify-content: center;

            svg {
                width: 14px;
                height: 14px;
                opacity: 0;
                transition: 0.3s ease opacity;
            }
        }

        span {
            .secondary {
                color: $colorSecondary;
            }

            .primary {
                color: $colorPrimary;
            }

            a {
                color: $green;
            }
        }
    }

    &.small {
        i {
            min-width: 16px;
            height: 16px;
            margin-right: 8px;

            svg {
                width: 10px;
                height: 10px;
                opacity: 0;
                transition: 0.3s ease opacity;
            }
        }
    }

    input {
        display: none;

        &:checked+.text_wrapper i svg {
            opacity: 1;
        }

        &:disabled~.text_wrapper {
            cursor: default;
        }

        &:checked+.text_wrapper i {
            background-color: $bgPrimary;
        }

        &.inversion {
            &:checked+.text_wrapper i {
                background-color: $green;
                border: none;
            }
        }
    }
}

@import 'styles/variables';

.header {
    padding: 10px 0;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__phone {
        color: #27273f;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 12px;

        @media (min-width: 400px) {
            font-size: 18px;
        }
    }
}

@import 'styles/variables';

.uploadCard {
    background-color: $bgPrimary;
    border-radius: 16px;
    padding: 20px;
    position: relative;
    min-height: 295px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include for-tablet-land {
        min-height: 275px;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        height: 100%;
        width: 100%;

        @include for-tablet-land {
            flex-direction: row;
            gap: 45px;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &__photo {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        cursor: default;

        &.notUploaded {
            gap: 16px;
            background-color: #fff;
            flex-direction: column;
            border: 2px dashed $borderPrimary;
            padding: 32px 15px;
            cursor: pointer;
        }

        &.errorFrame {
            border-color: $red;
            background-color: #FFF1F0;
        }

        @include for-tablet-land {
            &:hover {
                .uploadCard__action {
                    opacity: 1;
                }
            }
        }
    }

    &__placeholder {
        height: auto;
        max-height: 100%;
    }

    &__userPhoto {
        height: 100% !important;
        max-height: 210px;
        width: auto !important;
        max-width: 100% !important;
    }

    &__success {
        width: 32px !important;
        height: 32px !important;
        position: absolute;
        left: 25px;
        top: 30px;
    }

    &__error {
        width: 16px;
        height: 16px;
    }

    &__action {
        opacity: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        transition: opacity .3s;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        border-radius: 16px;

        &.active {
            opacity: 1 !important;
            pointer-events: auto;
        }

        @include for-tablet-land {
            flex-direction: row;
            pointer-events: auto;
        }
    }

    &__actionBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 93px;

        @include for-tablet-land {
            flex-direction: column;
            width: auto;
        }

        &:first-child {
            margin-right: 0;
            margin-bottom: 8px;

            @include for-tablet-land {
                margin-right: 20px;
                margin-bottom: 0;
            }
        }

        .changeButton {
            color: $green;
        }

        .deleteButton {
            color: $red;
        }

        img {
            width: 24px;
            height: 24px;
            margin-bottom: 4px;

            @include for-tablet-land {
                width: 32px;
                height: 32px;
            }
        }
    }

    &__loader {
        position: relative;
    }

    .title {
        word-break: break-word;
        display: block;
        color: $green;
        text-decoration: underline;
        text-align: center;
    }

    input[type="file"] {
        display: none;
    }

    .errorText {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__tip {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;
        align-self: stretch;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: rgba(39, 39, 63, 0.7);

        &_error {
            .uploadCard__tipHeader {
                color: $red;
            }
        }

        @include for-tablet-land {
            flex-direction: column;
            gap: 16px;
            align-self: auto;
            justify-content: flex-start;
        }
    }

    &__tipHeader {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        color: $colorPrimary;
        margin-bottom: 8px;
    }

    &__tipImages {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        flex-shrink: 0;
    }

    &__tipImgWrapper {
        position: relative;
        width: 80px;
        border-radius: 6px;
        border: 1px solid $green;
        background-color: #fff;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% + 2px);
            height: 60%;
            transform: translate(-50%, -50%);
            background-color: #fff;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 60%;
            height: calc(100% + 2px);
            transform: translate(-50%, -50%);
            background-color: #fff;
        }

        &_wrong {
            border-color: $red;
        }
    }

    &__tipImg {
        position: relative;
        display: block;
        width: auto !important;
        max-width: 100% !important;
        z-index: 1;
    }

    &__tipIcon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
}

@import "styles/variables";

.title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 16px;

    @include for-tablet {
        margin-bottom: 0;
        margin-right: 32px;
    }
}

.titleWrapper {
    margin-bottom: 32px;
    display: flex;
    align-items: baseline;
    flex-direction: column;

    @include for-tablet {
        flex-direction: row;
    }
}

.wrapper {
    margin: 24px 0;
}

.messageWrapper {
    margin-bottom: 32px;
}

.special_equipment {
    color: $green;
    cursor: pointer;
}

.paragraph {
    margin-bottom: 10px;
}

.infoWrapper {
    display: grid;
    gap: 20px;
    margin-bottom: 24px;

    @include for-tablet-land {
        div:first-child {
            grid-column-start: 1;
            grid-column-end: 4;
        }

        grid-template-columns: repeat(3, 1fr);
    }

    .info {
        display: flex;
        flex-direction: column;

        &__label {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 20px;
            color: $colorSecondary;
        }

        &__text {
            font-size: 16px;
            line-height: 16px;
            color: $colorPrimary;
        }
    }
}

.inputsGroup {
    display: grid;
    grid-gap: 32px 20px;
    grid-template-columns: 1fr;

    @include for-tablet-land {
        grid-template-columns: repeat(3, 1fr)
    }
}

.row {
    @include for-tablet {
        grid-column-start: 1;
        grid-column-end: 4;
    }
}

.photoWrapper {
    @extend .row;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    @include for-tablet-land {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px 16px;
    }

    h4 {
        font-size: 18px;
        line-height: 24px;
    }

    .photoDesc {
        font-size: 18px;
        line-height: 32px;
        color: #878B90;
    }

    .photoText {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .ptsPhotos {
        display: flex;
        flex-direction: column;
        gap: 32px;

        @include for-tablet-land {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px 32px;
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    .photoFragment {
        display: flex;
        flex-direction: column;
        gap: 32px;

        @include for-tablet-land {
            display: grid;
            align-content: start;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
        }
    }

    hr {
        background: #EAEDF0;
        width: 100%;
        height: 1px;
        grid-column-start: 1;
        grid-column-end: 3;

        @include for-tablet-land {
            display: none;
        }
    }

    .photoCard {
        // background-color: $bgPrimary;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        padding: 20px;
        gap: 20px;

        @include for-tablet-land {
            flex-direction: row;
            padding: 24px;
            gap: 24px;

            &>div {
                flex: 1;
                flex-shrink: 0;
            }
        }
    }
}

.photoWrapperAuto {
    @extend .row;
    display: grid;
    gap: 16px;
    width: 100%;

    @include for-tablet-land {
        grid-template-columns: repeat(4, 1fr);
        gap: 32px 20px;
        align-items: flex-start;
    }
}

.stsWrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    @include for-tablet-land {
        grid-template-columns: repeat(2, 1fr)
    }
}

.checkboxTitle {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;

    @include for-tablet {
        font-size: 18px;
        line-height: 26px;
    }
}

.subCheckbox {
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
    padding-left: 40px;

    @include for-tablet {
        font-size: 16px;
        line-height: 24px;
    }
}

.messageLink {
    color: $green;
}

.ptsTabs {
    display: flex;
    margin: 0 0 24px;

    &>div>div>div {
        padding-bottom: 0;
    }
}

.note {
    color: $colorSecondary;
    font-size: 13px;
    line-height: 18px;

    &:first-child {
        margin-bottom: 10px;
    }
}

.infoPanelv1 {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin-top: 32px;
    padding: 16px 20px;
    border-radius: 8px;
    background-color: #EFFBEF;

    img {
        width: 24px;
        height: 24px;
    }
}

.infoPanel {
    margin-top: 32px;
    padding: 16px 20px;
    border-radius: 8px;
    background-color: #EFFBEF;

    .progressBarWrapper {
        display: flex;
        gap: 12px;
        margin-bottom: 20px;

        .progressBar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            border-radius: 6px;
            background: #94EB8F;
            width: 234px;
            height: 24px;
            padding-right: 26px;
            color: #fff;
            font-size: 14px;
            line-height: 20px;

            &::before {
                content: '';
                position: absolute;
                width: 95%;
                height: 24px;
                left: 0;
                top: 0;
                border-radius: 6px;
                background: #5BC564;
            }

            span {
                position: relative;
                z-index: 1;
            }
        }

        img {
            width: 20px;
            height: 20px;
        }
    }
}

.divider {
    height: 1px;
    background: #D9D9D9;
    margin: 40px 0;

    @include for-tablet-land {
        background: transparent;
        margin: 20px 0;
    }
}

.tooltipWrapper {
    color: #fff;

    .tooltipBody {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .tooltipItem {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        img {
            width: 20px;
            height: 20px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .tooltipImageWrapper {
        display: flex;
        flex-direction: column;
        padding: 12px;
        gap: 8px;
        background: #171923;
        border-radius: 8px;
    }

    .tooltipSearchExample {
        color: #878B90;
        display: flex;
        align-items: center;
        gap: 8px;

        img {
            width: 16px;
            height: 16px;
        }
    }

    .tooltipImg {
        display: block;
        border-radius: 8px;
    }
}

@import './variables';

body {
    font-family: $baseFontSize;
    font-size: 16px;
    line-height: 24px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: $colorPrimary;
    min-width: 320px;
}

#root-tooltip,
#root-modal {
    position: relative;
    z-index: 20;
}

div[class^="Snackbar_snackbar-wrapper"],
div[class*=" Snackbar_snackbar-wrapper"] {
    z-index: 40;
    white-space: pre-wrap;
    margin: 85px auto;
    @include for-mobile-only{
      padding: 0 20px;
    }
}
div[class*="Snackbar_snackbar-wrapper-top-right"]  {
    z-index: 40;
    white-space: pre-wrap;
    margin: 85px auto;
    max-width: 1440px;
    @include for-mobile-new{
        margin: 85px auto;
        justify-content: center;
    }

    @include for-tablet-land {
        max-width: 1440px;
        padding-right: 40px;
        justify-content: flex-end;
    }

    @include for-notebook {
        max-width: 1440px;
        padding-right: 40px;
    }

    @include for-desktop {
        max-width: 1440px;
        margin: 85px auto 0 0;
        padding-right: 40px;

    }
    @include for-wide {
        max-width: 1060px;
        margin: 85px auto;
        padding-right: 0;
    }
}

.container {
    max-width: 1160px;
    margin: 0 auto;
    box-sizing: border-box;
    @media screen and (max-width: $notebook) {
        max-width: 760px;
    }
     @media screen and (max-width: $tablet) {
         padding: 0 5.6%
    }

}

.fullWidth{
    width: 100%;
}

.baseMarginBottom {
    margin-bottom: 40px;
}

.textMainColor{
    color: $green;
}

.sectionTitle{
    @extend .baseMarginBottom;
    color: var(--grey-900, $colorPrimary);
    text-align: center;
    font-family: $baseFontSize;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    @include for-mobile-only{
        font-size: 24px;
        margin-bottom: 24px;
    }
}
.ant-slider .ant-slider-handle::after{
    box-shadow: 0 0 0 3px #4CAF50;
}
.ant-slider .ant-slider-handle:hover::after{
        box-shadow: 0 0 4px 4px rgba(0, 255, 117, .4);
        border: 4px solid #4CAF50;
}
 .ant-slider .ant-slider-handle:active::after{
        box-shadow: 0 0 4px 4px rgba(0, 255, 117, .4);
        border: 4px solid #4CAF50;
}
.ant-slider .ant-slider-handle:focus::after{
        box-shadow: 0 0 4px 4px rgba(0, 255, 117, .4);
        border: 7px solid #4CAF50;
}


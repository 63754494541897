@import './variables';

.text {
    &:first-child {
        padding-top: 0 !important;
        margin-top: 0 !important;
    }


    * {
        margin: 16px 0 0 0 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: $colorPrimary !important;
    }
   p img {
      width: 100% !important;
      height: auto!important;
    }

    a {
        color: $green !important;
    }

    ul {
        padding-inline-start: 10px;

        li {
            position: relative;
            padding-left: 10px;

            &::marker {
                content: '\25CF';
                color: $green;
            }
        }

        li + li {
            margin-top: 24px;
        }
    }

    ol {
        padding-inline-start: 18px;

        li + li {
            margin-top: 12px;
        }
    }
    .mainColor{
        color: $green;
    }
}

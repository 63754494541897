@import 'styles/variables';

.uploadCard {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        height: 100%;
        width: 100%;
    }

    &__photo {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        cursor: default;

        &.notUploaded {
            gap: 16px;
            background-color: #fff;
            flex-direction: column;
            border: 2px dashed $borderPrimary;
            padding: 32px 16px;
            cursor: pointer;
        }

        &.errorFrame {
            border-color: $red;
            background-color: #FFF1F0;
        }

        @include for-tablet-land {
            min-height: 225px;

            &:hover {
                .uploadCard__action {
                    opacity: 1;
                }
            }
        }
    }

    &__placeholder {
        height: auto;
        max-height: 100%;
        width: 44px;

        &_wide {
            width: 176px;
        }
    }

    &__userPhoto {
        height: 100% !important;
        max-height: 210px;
        width: auto !important;
        max-width: 100% !important;
    }

    &__success {
        width: 32px !important;
        height: 32px !important;
        position: absolute;
        left: 25px;
        top: 30px;
    }

    &__error {
        width: 16px;
        height: 16px;
    }

    &__action {
        opacity: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        transition: opacity .3s;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        border-radius: 16px;

        &.active {
            opacity: 1 !important;
            pointer-events: auto;
        }

        @include for-tablet-land {
            flex-direction: row;
            pointer-events: auto;
        }
    }

    &__actionBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 93px;

        @include for-tablet-land {
            flex-direction: column;
            width: auto;
        }

        &:first-child {
            margin-right: 0;
            margin-bottom: 8px;

            @include for-tablet-land {
                margin-right: 20px;
                margin-bottom: 0;
            }


        }

        .changeButton {
            color: $green;
        }

        .deleteButton {
            color: $red;
        }

        img {
            width: 24px;
            height: 24px;
            margin-bottom: 4px;

            @include for-tablet-land {
                width: 32px;
                height: 32px;
            }
        }
    }

    &__loader {
        position: relative;
    }

    .title {
        word-break: break-word;
        display: block;
        text-align: left;
        color: $green;
        text-decoration: underline;
        text-align: center;
    }

    .subTitle {
        align-self: flex-start;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include for-tablet-land {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    input[type="file"] {
        display: none;
    }

    .errorText {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .tooltipLink {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #375DFB;
        text-decoration: underline;
        cursor: pointer;
    }
}

.tooltipWrapper {
    color: #fff;

    .tooltipBody {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .tooltipItem {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        img {
            width: 20px;
            height: 20px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .tooltipImageWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 12px;
        gap: 8px;
        background: #171923;
        border-radius: 8px;
    }

    .tooltipSearchExample {
        color: #878B90;
        display: flex;
        align-items: center;
        gap: 8px;

        img {
            width: 16px;
            height: 16px;
        }
    }

    .tooltipImg {
        display: block;
    }
}

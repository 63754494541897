@import "styles/variables";

.settings {

    &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 32px;
    }





}

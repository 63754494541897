@import "styles/variables";

.confirmation {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    @include for-tablet-land {
        grid-template-columns: repeat(2, 1fr);
    }
}
.title {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
}
.listWrapper{
    list-style: none;
    li{
        margin-left: 24px;
        position: relative;
        span{
            font-weight: bold;
        }
        &:not(:last-child){
            margin-bottom: 8px;
        }
        &:before{
            content: "";
            width: 6px;
            height: 6px;
            position: absolute;
            background: $green;
            border-radius: 50%;
            left: -15px;
            bottom: 10px;
        }
    }
}
.imageWrapper{
    margin: 32px 0;
    display: flex;
    gap: 20px;
    @include for-mobile-only{
        & img {
            width: 30%;
            height: auto;
        }
    }
}

.idxDescription{
     margin: 32px 0;
 }

.idxWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.grow{
    @include for-mobile-only{
        flex-grow: 1;
    }
}
.photoWrapper{
    display: flex;
    justify-content: center;
    width: 460px;
    background: rgba(135, 139, 144, 0.2);
    padding-top: 20px;
    border-radius: 16px;
    @include for-mobile-only{
        width: 100%;
    }
}
.arrowLoader{
    position: absolute;
    cursor: pointer;
    left: calc(50% - 44px);
    top: calc(50% - 44px);
    width: 88px;
    height: 88px;
    background: $green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 40px;
        height: 40px;
    }
}

.photoDescriptionTitle{
    margin: 32px 0 16px;
}
.elevationWrapper{
    position: absolute;
    width: 250px;
    //height: 168px;
    left: calc(50% - 125px);
    top: calc(50% - 65px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    gap: 12px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 16px;
}
.success{
    color: $green;
}
.error{
    color:$red;
}
.sendButton{
    position: absolute;
    width: 250px;
    //height: 168px;
    left: calc(50% - 125px);
    top: calc(50% - 20px);
}
.iframeWrapper{
    height: 345px;
    width: 460px;
    @include for-mobile-only{
        width: 100%;
        height: 460px;
    }
}
.iframe {
    width: 100%;
    height: 100%;
}

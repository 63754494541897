@import 'styles/variables';

.SnackbarMessage {
    display: flex;
    align-items: center;
    font-size: 14px;
    position: relative;
    background: transparent;
}
.messageWrapper {
    margin-left: 15px;
    @include for-mobile-only{
        margin-left: 5px;
    }
}
.countdownCircle{
    @include for-mobile-only{
        transform: scale(0.8);
    }
}
.circleProgress_wrapper{
    position: absolute;
    width: 44px;
    height: 44px;
    left: 2px;
    top: 2px;
}

.wrapper{
    width: 22px;
    height: 44px;
    position: absolute;
    top:0;
    overflow: hidden;
}
.right{
    right:0;
}
.left{
    left:0;
}
.circleProgress{
    width: 44px;
    height: 44px;
    border: 2px solid white;
    border-radius: 50%;
    position: absolute;
    top:0;
    transform: rotate(45deg);
}
.rightcircle{
    border-top: 2px solid white;
    border-right: 2px solid white;
    right:0;
    animation: circleProgressLoad_right 5s linear forwards;
}
.leftcircle{
    border-bottom: 2px solid white;
    border-left: 2px solid white;
    left:0;
    animation: circleProgressLoad_left 5s linear forwards;
}
@keyframes circleProgressLoad_right{
    0%{
        border-top: 2px solid white;
        border-right: 2px solid white;
        transform: rotate(225deg);
    }
    50%{
        border-top: 2px solid gray;
        border-right: 2px solid gray;
        border-left: 2px solid white;
        border-bottom: 2px solid white;
        transform: rotate(225deg);
    }
    100%{
        border-top: 2px solid gray;
        border-right: 2px solid gray;
        border-left: 2px solid gray;
        border-bottom: 2px solid gray;
        transform: rotate(45deg);
    }
}
@keyframes circleProgressLoad_left{
    0%{
        border-bottom: 2px solid white;
        border-left: 2px solid white;
        transform: rotate(225deg);
    }
    50%{
        border-bottom: 2px solid gray;
        border-left: 2px solid gray;
        border-top: 2px solid white;
        border-right: 2px solid white;
        transform: rotate(45deg);
    }
    100%{
        border-top: 2px solid gray;
        border-right: 2px solid gray;
        border-bottom: 2px solid gray;
        border-left: 2px solid gray;
        transform: rotate(45deg);
    }
}

@import "styles/variables";

.head {
    margin-bottom: 24px;
}

.hint {
    font-size: 13px;
    color: $blue;
}

.titleWrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;
    flex-direction: column;

    .title {
        margin-bottom: 0;
    }

    @include for-tablet {
        flex-direction: row;
        margin-bottom: 24px;
        align-items: center;
    }
}

.title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 32px;

    @include for-tablet {
        margin-bottom: 24px;
    }
}

.message {
    flex-grow: 1;
    margin-top: 32px;

    @include for-tablet {
        margin-left: 32px;
        margin-top: 0;
    }
}

.checkboxGroup {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
}

.insideCheckboxGroup {
    display: grid;
    grid-gap: 32px 20px;
    margin: 8px 0 8px 0;

    @include for-tablet {
        grid-template-columns: repeat(3, 1fr);
    }
}

.inputsGroup {
    display: grid;
    grid-gap: 32px 20px;
    grid-template-columns: 1fr;

    @include for-tablet {
        grid-template-columns: repeat(3, 1fr)
    }
}

.wideColumn {
    @include for-tablet {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.afterWideColumn {
    @include for-tablet {
        grid-column-start: 1;
    }
}

.row {
    @include for-tablet {
        grid-column-start: 1;
        grid-column-end: 4;
    }
}

.newRow {
    @include for-tablet {
        grid-column-start: 1;
        grid-column-end: 2;
    }
}

.photos {
    display: grid;
    gap: 16px;

    @include for-tablet-land {
        grid-template-columns: repeat(4, 1fr);
        gap: 32px 20px;
    }
}

.wrapper {
    &>div:not(:last-child) {
        margin-bottom: 24px;
    }
}

.mainColor {
    color: $green;
    cursor: pointer;
    width: fit-content;
}

.errorColor {
    color: $red;
    cursor: pointer;
    width: fit-content;
}

.checkboxWrapper {
    width: fit-content;
}

.docs {
    display: grid;
    grid-gap: 32px;

    b {
        font-size: 24px;
    }

    a {
        font-size: 20px;
        color: #4CAF50;
        text-decoration: underline;
        padding-left: 40px;
        position: relative;

        &::before {
            content: url("../../../img/pdf.svg");
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 32px;
        }
    }
}

.contactActions {
    display: flex;
    justify-content: space-between;
}

.publicWrapper {
    display: flex;
    align-items: center;
    margin: 0 0 8px;
}

.publicMain {
    font-size: 12px;
    line-height: 1;
}

.publicSecondary {
    font-size: 10px;
    line-height: 1;
}

.modal {
    a {
        color: #4CAF50;
    }
}

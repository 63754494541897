@import 'styles/variables';

.selectStyles{
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  outline: none;
  color: black;
  &:focus {
    outline: none;
  }

}

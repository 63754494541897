@import 'styles/variables';
@import 'styles/global';


.calculator__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    &_step {
        @include for-notebook {
            width: 60%;
        }
    }

    .main_slider {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 58px;
        padding-bottom: 34px;
    }

    .inputWrapper {
        display: flex;
        align-items: center;
        font-family: $baseFontSize;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 6px 0;

        @include for-notebook {
            font-size: 20px;
            line-height: 26px;
        }

        input {
            border: none;
            width: fit-content;
            font-family: $baseFontSize;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            padding: 0;
            margin: 0;
            line-height: 24px;
            background: transparent;

            @include for-notebook {
                font-size: 20px;
                line-height: 26px;
            }
        }

        span {
            cursor: pointer;
        }
    }

    .sliderWrapper {
        display: flex;
        flex-direction: column;
        background-color: $bgPrimary;
        border-radius: 12px;
        padding: 8px 12px 0;
        border: 1px solid transparent;
        transition: border-color 0.2s;

        &:hover {
            border-color: #e2e4e9;
            cursor: pointer;
        }

        .sliderTitle {
            color: #608185;
            font-family: $baseFontSize;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            @include for-notebook {
                line-height: 16px;
            }
        }

        .sliderResult {
            font-family: $baseFontSize;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }

        .slider {
            margin-bottom: -34px;
        }
    }

    .calculator__more {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border-radius: 8px;
        padding: 8px 16px;
        background-color: #EFFBEF;
        color: $green;
        text-align: right;
        font-family: $baseFontSize;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        cursor: pointer;

        .calculator__moreIcon {
            width: 16px;
            height: 16px;
        }
    }

    .calculator__resultCard {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;

        .calculator__resultWrapperTitle {
            color: #608185;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 6px;

            @include for-notebook {
                margin-bottom: 4px;
                line-height: 16px;
            }
        }

        .calculator__money {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            @include for-notebook {
                font-size: 20px;
                line-height: 26px;
            }
        }

        .calculator__currency {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-left: 5px;

            @include for-notebook {
                font-size: 20px;
                line-height: 26px;
            }
        }

        .calculator__resultWrapperText {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
}

.name {
    display: none;
}

.moreMoneyModal {
    p {
        margin-bottom: 24px;
    }

    a {
        color: $green;
    }
}

.phoneWrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;

    button {
        width: 100% !important;
    }

    @include for-notebook {
        width: auto;
        flex: 1;
        align-self: stretch;
    }
}

.phone {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    p {
        margin-top: 8px;
        color: #6E7491;
        font-size: 14px;
        line-height: 16px;

        a {
            color: #4CAF50;
        }
    }
}

.phoneModal {
    p {
        margin-bottom: 12px;
    }
}

.moreModal {
    position: absolute;
    top: 50%;
    left: 16px;
    right: 16px;
    transform: translateY(-50%);
    text-align: center;

    @include for-tablet {
        position: static;
        transform: none;
        margin: -24px;
    }

    img {
        display: inline-block;
        margin-bottom: 20px;
    }

    h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 4px;
    }

    p {
        font-size: 14px;
        line-height: 18px;
        color: #608185;
        margin-bottom: 20px;
    }

    .moreModalCard {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid #E2E4E9;
        background-color: #F6F8FA;

        &>div {
            display: flex;
            justify-content: flex-start;
            gap: 12px;
            text-align: left;

            svg {
                flex-shrink: 0;
            }
        }
    }

    hr {
        margin: 20px -16px
    }
}

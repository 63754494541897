@import 'styles/variables';

.promoInfo{
    font-family: $baseFontSize;
    padding: 100px 0;
    display: flex;
    @include for-mobile-only {
        padding: 32px 0;
    }
    .title{
        color: $colorPrimary;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px; /* 100% */
        margin-bottom: 40px;
        @include for-mobile-only {
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
    .colored{
        @extend .title;
        color: $green;

    }
    .stepsWrapper{
        display: flex;
        gap: 40px;
        @include for-mobile-only{
            gap: 36px;
        }
        & > div {
            width: 33%;
            @include for-mobile-only {
              width: 100%;
            }
        }
        @include for-mobile-only {
            flex-direction: column;
        }
        .step{
            color: $green;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 52px; /* 108.333% */
            @include for-mobile-only {
                display: flex;
                gap: 30px;
                align-items: flex-start;
            }
            .stepTitleWrapper{
                display: flex;
                gap: 40px;
                align-items: center;
                .stepNumber{
                    color: $green;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 52px; /* 108.333% */
                    @include for-mobile-only{
                        width: 56px;
                    }
                }
                .line{
                    height: 2px;
                    flex: 1 0 0;
                    border-radius: 4px;
                    background: $green;
                    @include for-mobile-only {
                        display: none;
                    }
                }
            }
            .textWrapper{
                @include for-mobile-only {
                    display: flex;
                   flex-direction: column;
                    gap: 10px;
                }
                .stepTitle{
                    color: $colorPrimary;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px; /* 144.444% */
                }
                .stepDescription{
                    color: $iconPrimary;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 150% */
                }
            }
        }
    }
    .buttonWrapper{
        margin-top: 60px;
        width: 310px;
        @include for-mobile-only {
            width: 100%;
            margin-top: 20px;
        }
    }

}

@import 'styles/variables';
.footer{
    padding: 40px 0;
    background: white;
    .footerWrapper{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1 0 0;
        @media screen and (max-width: $tablet) {
           flex-direction: column;
           justify-content: center;
           align-items: center;
        }
    }
    .footerDescriptionWrapper{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        @media screen and (max-width: $tablet) {
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 15px;
        }
        @include for-mobile-only{
            gap: 30px;
        }
        p {
            display: flex;
            width: 700px;
            flex-direction: column;
            color: var(--grey-900, $colorPrimary);
            font-family: $baseFontSize;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            @media screen and (max-width: $notebook) {
                width: 500px;
            }
            @media screen and (max-width: $tablet) {
                width: 100%;
            }
        }
        a{
            color: $colorSecondary;
            font-family: $baseFontSize;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

    }
    .footerPhoneWrapper{
        display: flex;
        width: 260px;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (max-width: $tablet) {
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 16px;
        }
        .footerPhone{
            color: var(--grey-900, $colorPrimary);
            font-family: $baseFontSize;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .footerHotLine{
            color: var(--grey-900, $colorPrimary);
            font-family: $baseFontSize;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 20px;
            @include for-mobile-only{
                margin-top: 7px;
            }
        }
    }
    .footerEntity{
        color: $colorPrimary;
        font-family: $baseFontSize;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 16px;
        display: block;
        @include for-mobile-only{
            margin-top: 32px;
        }
    }
}
.modalContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.buttonWrapper{
    width: fit-content;
    margin: 0 auto;
}
.modal__errorMessage {
    color: $red;
}
.modalContainerSuccess{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

@import 'styles/variables';
@import 'styles/global';

.calculatorSection {
    background-color: #21232D;
    border-radius: 0 0 16px 16px;
    padding: 20px 0;

    @include for-notebook {
        background-color: #fff;
    }

    .sectionWrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: hidden;

        @include for-notebook {
            border-radius: 16px;
            padding: 24px 24px 24px 40px;
            background: linear-gradient(270deg, #21232D 45%, #31353F 100%);
        }
    }

    .bannerCalc {
        display: flex;
        flex-direction: column;
        border-radius: 12px 12px 0 0;
        overflow: hidden;

        @include for-notebook {
            flex-direction: row;
            gap: 24px;
        }

        .textWrapper {
            padding: 24px 16px;
            background: url(./../../img/calc-banner-bg-mobile.png) #31353F no-repeat right/contain;


            @include for-notebook {
                flex: 1;
                padding: 20px 0 0;
                background: none;
            }

            .info {
                display: flex;
                align-items: center;
                color: $green;
                font-family: $baseFontSize;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;

                @include for-notebook {
                    font-size: 20px;
                    line-height: 26px;
                }
            }

            .round {
                width: 4px;
                height: 4px;
                margin-right: 10px;

                @include for-notebook {
                    width: 8px;
                    height: 8px;
                }
            }

            .title {
                width: 80%;
                font-family: $baseFontSize;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                color: #fff;
                margin-top: 8px;

                @include for-notebook {
                    font-size: 48px;
                    line-height: 54px;
                    margin-top: 16px;
                    margin-bottom: 58px;
                }
            }

            .titleGradient {
                @include for-notebook {
                    background: linear-gradient(90deg, #5DDE68 45%, #B1FFB8 100%);
                    background-clip: text;
                    color: transparent;
                }
            }
        }

        .calculatorCardWrapper {
            @include for-notebook {
                max-width: 400px;
            }
        }

        .calculatorCard {
            display: flex;
            padding: 20px 16px 16px;
            gap: 24px;
            border-radius: 0 0 16px 16px;
            background: #fff;

            @include for-notebook {
                padding: 20px;
                border-radius: 16px;
            }
        }
    }
}

.agreements {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #fff;
    margin-top: 16px;

    a {
        @extend .agreements;
        color: #4CAF50;
    }
}

.tags {
    width: 100%;

    &Mobile {
        @include for-notebook {
            display: none;
        }
    }

    &Desktop {
        display: none;

        @include for-notebook {
            display: block;
            border-radius: 16px;
            box-shadow: 0px 16px 32px 0px #585C5F1F;
            background: url(./../../img/calc-banner-bg.png) #31353F no-repeat right/contain;
        }
    }

    .tagsWrapper {
        display: flex;
        gap: 12px;
        flex-direction: column;
        border-radius: 12px;
        padding: 16px;
        background-color: #31353F;

        @include for-notebook {
            gap: 8px;
            padding: 32px 24px 100px;
            background: none;
        }

        .tag {
            display: flex;
            align-items: center;
            gap: 12px;
            color: #fff;
            font-family: $baseFontSize;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;

            img {
                width: 16px;
                height: 16px;
            }

            @include for-notebook {
                font-size: 16px;
                line-height: 24px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

.guarantee {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #fff;
    padding: 0 0 32px 24px;
}

@import 'styles/variables';

.message {
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.3s ease opacity;
}

.icon {
  width: 24px;
  height: auto; //for Safari 11
  margin-right: 16px;
}

.text {
  p {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}
// mod

.shadow {
  box-shadow: $shadowPrimary;
}

.hide {
  opacity: 0;
}

.type {
  &-success {
    background-color: $accept;
  }
  &-warning {
    background-color: $warning;
  }
  &-error {
    background-color: $error;
  }
}

.size {
  &-default {
    padding: 8px 20px;
  }
  &-big {
    padding: 16px 20px;
  }
  &-biggest {
    padding: 16px 20px;
    font-size: 14px;
    border-radius: $borderRadiusMiddle;
    @include for-tablet {
      padding: 30px;
      border-radius: $borderRadiusLarge;
      font-size: 16px;
    }
  }
}

.centered {
  text-align: center;
  display: block;

  .icon {
    margin: 0 0 14px 0;
  }
}

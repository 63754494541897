@import "styles/variables";

.titleWrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;

  &--withMessage {
    @include for-tablet {
      margin-bottom: 24px;
    }
  }

  @include for-mobile-only {
    flex-direction: column;
  }

}

.title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;

  @include for-tablet {
    margin-right: 32px;
  }
}

.message {
  margin-top: 32px;

  @include for-tablet {
    margin-top: -9px;
    flex-grow: 1;
  }
}

.wideColumn {
  @include for-tablet {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  @include for-tablet-land {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.wideColumnDesktop {}

.superWideColumn {
  &__desc {
    font-size: 12px;
    color: gray;
    margin-top: 8px;
  }

  @include for-tablet {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.middleColumn {
  @include for-tablet {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.row {
  @include for-tablet {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.newRow {
  @include for-tablet {
    grid-column-start: 1;
  }
}

.flexColumn {
  display: flex;
  align-items: center;
  gap: 16px;

  &>div {
    flex-basis: 50%;
  }

  @include for-tablet {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @include for-tablet_land {
    align-items: flex-start;
    grid-column-start: auto;
    grid-column-end: auto;
  }
}

.reduceGap {
  margin-top: -29px;
  margin-bottom: -20px;

  @include for-tablet {
    padding: 20px 0;
  }

}

.checkboxWrapper {
  display: flex;

  @include for-mobile-only {
    flex-direction: column;
    margin-bottom: 32px;
  }

  label {
    min-width: 240px;
    width: 100%;
    max-width: 250px;

    @include for-mobile-only {
      margin-top: 24px;
    }

  }

  &>div {
    margin-left: 20px;
    width: 100%;

    @include for-mobile-only {
      margin-left: 0;
      margin-top: 24px;
    }
  }
}

.checkboxGroup {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @include for-tablet {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.checkboxOne {
  margin-top: -8px;

  @include for-tablet {
    margin-top: 0;
    grid-column-start: 1;
    grid-column-end: 4;
  }

  @include for-tablet-land {
    grid-column-start: 3;
    grid-column-end: 4;
  }
}

.inputsGroup {
  &__main {
    display: grid;
    grid-gap: 32px 20px;
    grid-template-columns: 1fr;

    @include for-tablet {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__codeAndNumber {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    @include for-tablet {
      grid-column-end: span 2;
      grid-gap: 20px;
    }
  }

  &__second {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px 20px;

    @include for-tablet {
      grid-template-columns: 1fr 1fr;
      grid-column-end: span 2;
    }
  }
}

.buttonWrapper {
  display: flex;
  margin-top: 12px;
  gap: 16px;
}

.onlineLoanPersonalModal {
  &>p {
    margin: 32px 0 40px;

    &>span {
      display: block;
      margin-top: 10px;
    }
  }

  &__img {
    margin: 0 auto 40px auto;
    display: block;
    width: 48px;
    height: 48px;
  }

  button {
    display: flex;

    span {
      line-height: 100% !important;

      .userExistButtonTitle {
        h4 {
          font-size: 18px;
          font-weight: 500;
        }

        p {
          font-size: 13px;
          color: rgba(255, 255, 255, 0.7);
          font-weight: 400;
        }
      }
    }
  }


}

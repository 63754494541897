@import 'styles/variables';

.ConfirmCodeInput {

  &__wrapper {
    position: relative;
    width: 50%;
    @include for-mobile-only {
      width: 160px;
    }
  }

  &__placeholder {
    padding: 0 4px;
    font-size: 18px;
    color: $colorSecondary;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 15px;
    pointer-events: none;
    transform: translateY(-50%);
    transform-origin: left;
    transition: transform 0.2s;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -4px;
      right: -4px;
      bottom: 0;
      border-radius: 50%;
      background-color: $bgSecondary;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &--active {
      transform: scale(0.8) translateY(calc(-50% - 36px));
      &::before {
        opacity: 1;
      }
    }
  }

  &__field {
    background-color: $bgSecondary;
    border: 1px solid $borderPrimary;
    border-radius: $borderRadiusMiddle;
    height: 56px;
    padding: 0 28px 0 18px;
    letter-spacing: 7px;
    width: 100%;
  }

  &__icon {
    display: none;
    position: absolute;
    top: calc(50% - 16px);
    right: -56px;

    &--loader {
      display: inline-block;
      animation: loader 1.3s linear infinite;
    }

    &--active {
      display: inline-block;
    }
  }

  &__error {
    color: $red;
    margin-top: 10px;
  }

  &__timer {
    display: inline-block;
    color: $colorSecondary;
    margin-top: 24px;
  }
}

@keyframes loader {
  100% {
    transform: rotate(360deg);
  }
}

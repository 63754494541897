@import 'styles/variables';
@import 'styles/global';

.aboutCompanySection {
    border-radius: 16px;
    background: #21232D;
    padding: 32px 0 48px;
    color: #fff;

    @include for-tablet-land {
        padding: 96px 0;
    }

    .title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        margin-bottom: 32px;

        @include for-tablet-land {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 48px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-bottom: 32px;

        @include for-tablet-land {
            flex-direction: row;
            padding-bottom: 48px;
        }
    }

    .officeImg {
        flex-shrink: 0;
        display: flex;
        align-items: flex-end;
        height: 420px;
        width: 100%;
        padding: 16px;
        border-radius: 12px;
        background-position: center;
        background-size: cover;

        p {
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
        }

        @include for-tablet-land {
            width: 370px;
            height: auto;

            p {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }

    .cardsContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include for-tablet-land {
            gap: 24px;
        }

        @include for-notebook {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .imageCard {
        display: none;
        border-radius: 12px;
        background-position: center;
        background-size: cover;

        @include for-notebook {
            display: block;
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        background-color: #31353F;

        @include for-tablet-land {
            flex-direction: column-reverse;
        }

        .cardFeature {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #21232D;
            padding: 8px 16px 8px 8px;
            border-radius: 8px;
            margin: 12px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;

            .iconWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80px;
                height: 36px;
                background-color: #31353F;
                border-radius: 6px;

                img {
                    width: 20px;
                    height: 20px;
                }
            }

            @include for-tablet-land {
                margin: 16px;
            }
        }

        .cardContent {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 16px 16px 30px 16px;

            h5 {
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
                margin-bottom: 16px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
                color: #A0A6B2;
            }

            @include for-tablet-land {
                padding: 24px;

                h5 {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }
    }

    .videoResponsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .videoResponsive iframe,
    .videoResponsive video {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        border-radius: 12px;
    }

    .link {
        color: $green;
    }
}

@import "styles/variables";

.StepChangeButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
    &__buttons {
        display: flex;
        flex-grow: 1;
        display: flex;
        align-self: center;
        justify-content: flex-end;
        gap: 25px;
        button{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px 40px;
            width: fit-content;
            &:active {
                display: flex;
                text-align: center;
                border: 2px solid $green;
            }
            @include for-mobile-only{
                padding: 0 40px;
            }
        }
        @include for-mobile-only{
            order: 2;
        }
        &--once {
            @include for-mobile-only{
                width: 100%;
                button {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }

        &--double {
            @include for-mobile-only {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 15px;
                button {
                    width: 100%;
                    box-sizing: border-box;
                    margin-right: 0;
                    padding: 0 20px;
                }
            }
        }
    }
    &__phone{
        display: flex;
        align-items:center;
        justify-content: center;
        a{
            font-weight: bold;
        }
        img{
            margin: 0 8px 4px 24px;
        }
        @include for-mobile-only{
            order: 3;
            width: 100%;
            margin-top: 32px;
        }
    }
}

.modal {
    a {
        color: #4CAF50;
    }
}

@import 'styles/variables';

.close {
    position: relative;
    cursor: pointer;
    margin: 5px;

    &--green {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $green;

        &::before,
        &::after {
            left: 14px;
            background-color: $colorInverse;
        }
    }

    &--simple {
        padding: 15px;

        &::before,
        &::after {
            left: 12px;
            background-color: $iconPrimary;
        }
    }

    &--tooltip {
        padding: 12px;
        margin: 0;

        &::before,
        &::after {
            left: 12px;
            background-color: #fff;
        }

        @include for-tablet-land {
            display: none;
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 21px;
        height: 2px;
        opacity: 0.8;
        transition: 0.3s ease opacity;
        border-radius: $borderRadiusSmall;
    }

    &::before {
        transform: rotate(45deg) translate(-6px, 5px);
    }

    &::after {
        transform: rotate(-45deg) translate(-5px, -6px);
    }

    &:hover {

        &::before,
        &::after {
            opacity: 1;
        }
    }
}

@import 'styles/variables';

.dadataWrapper{
    position: relative;
}
.iconWrapper{
    position: absolute;
    right: 6px;
    top: 50%;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
}
.hint {
    font-size: 13px;
    color: $blue;
}
.container{
    position: relative;
}
.optionWrapper {
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    z-index: 10;
    background-color: white;
    box-shadow: $shadowSecondary;
    cursor: pointer;
    max-height: 160px;
    border-radius: 8px;
    border: 1px solid transparent;
    overflow: hidden;

    &_high {
        max-height: 300px;
    }

    .option {
        padding: 8px;
        display: flex;
        align-items: center;
        background: white;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $colorPrimary;
        cursor: pointer;
        margin-left: 0;

        &.active,
        &:hover {
            background: #eef3f9
        }

        &:before {
            display: none;
        }
    }
}
.inputWrapper {
    position: relative;
    width: 100%;

    &--auto {
        input {
            cursor: pointer;
        }
    }

    .icon--chevron {
        pointer-events: none;
    }

    &.active {
        &Placeholder {
            .placeholder {
                width: auto;
                top: 0;
                transform: scale(0.8) translateY(-50%);

                &::before {
                    opacity: 1;
                }
            }
        }
        &Icon {
            .icon {
                transform: translateY(-50%) rotate(-180deg);
            }
            .input {
                border-color: $green;
            }
        }
    }

    &Confirm {
        width: 50%;
    }
}

input[type=number] {
    -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.input {
    display: block;
    background-color: $bgSecondary;
    border: 1px solid $borderPrimary;
    border-radius: $borderRadiusMiddle;
    min-height: 56px;
    width: 100%;
    padding: 0 28px 0 18px;
    text-overflow: ellipsis;
    outline: none;

    &Confirm {
        letter-spacing: 7px;
    }

    &:disabled {
        opacity: 0.85;
        pointer-events: none;
        background-color: rgba(242, 242, 242, 1);

        + img {
            opacity: 0.25;
        }
    }
}

.textarea {
    box-sizing: border-box;
    height: auto;
    padding: 15px 28px 15px 18px;
    resize: none;
    overflow: hidden;

    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;

    &:hover,
    &:focus {
        border-color: $borderPrimary;
        box-shadow: none;
    }
}

.placeholder {
    padding: 0 4px;
    font-size: 16px;
    color: $colorSecondary;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 15px;
    pointer-events: none;
    transform: translateY(-50%);
    transform-origin: left;
    transition: transform 0.2s, width 0.2s, top 0.2s;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85%;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -4px;
        right: -4px;
        bottom: 0;
        border-radius: 50%;
        background-color: $bgSecondary;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s;
    }
}

@import 'styles/variables';

.logo {
  display: block;
  background-repeat: no-repeat;
  background-position: center center;

  &.default {
      width: 214px;
      height: 54px;
      background-image: url(./img/logo.svg);
      @include for-mobile-only{
          width: 142px;
          height: 36px;
          background-size: contain;
      }
  }

  &.big {
    width: 214px;
    height: 54px;
    background-image: url(./img/logo.svg);
  }

  @include for-tablet-land {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.9;
    }

    &.default {
      width: 214px;
      height: 54px;
      background-image: url(./img/logo.svg);

    }
  }
}

@import 'styles/variables';

.loader {
  width: 40px;
  height: 40px;
  position: relative;

  &--white {
    div {
      background: white;
    }
  }

  &--green {
    div {
      background: $green;
    }
  }

  &--black {
    div {
      background: black;
    }
  }

  div {
    position: absolute;
    top: 16px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 4px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 4px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 16px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 28px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
